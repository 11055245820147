body {
  color: #474747;
  background-color: #FDF1EA;
}

a {
  text-decoration: none;
  color: #6096FD;
}

img {
  max-width: 100%;
  max-height: 100%;
}

textarea {
  margin: 1rem 0;
}

.App {

}

.App-logo {
  pointer-events: none;
}

.App-header {
  background-color: #FAA7B8;
  min-height: 86px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
}

.unicon {
  margin-right: 0.5rem;
}

.flex-container {
  margin-top: 30px;
}

.header-link {
  color: white;
}

.page-title {
  padding: 1.5rem 1rem 0.75rem 1rem;
  font-weight: 700;
  margin-bottom: 0;
}

.list-style-none {
  list-style-type: none;
  padding: 0 0.5rem;
}

.topic-card {
  width: 100%;
  border: none;
  border-radius: 2rem;
  margin-bottom: 1.25rem;
  padding: 0 1rem;
  height: 7.5rem;
  color: #363636;
  text-align: left;
}

.topic-card div {
  height: 100%;
  padding: 0 1rem;
}

.topic-title {
  margin: 1.5rem 1.25rem;
  vertical-align: middle;
}

.central-container {
  padding: 0 12vw;
}

.central-container input{
  box-shadow: 0.1rem 0.1rem 0.75rem 0 rgba(0, 0, 0, 0.1);;
}

.melrose-btn {
  color: #FFFFFF;
  background-color: #aab6fb;
  font-size: 1.25rem;
  padding: 0.75rem 1rem;
  text-align: center;
  width: 100%;
  border: none;
  border-radius: 2rem;
  margin: 1rem 0;
}

.carnation-btn {
  color: #FFFFFF;
  background-color: #FB7B8E;
  font-size: 1rem;
  padding: 0.5rem 0.5rem;
  text-align: center;
  width: 100%;
  border: none;
  border-radius: 2rem;
  margin: 0.75rem 0;
  height: 2.5rem;
}

.blueberry-icon {
  color: #6096FD;
}

.blueberry-card {
  color: #FFFFFF;
  background-color: #6096FD;
  font-size: 1.5rem;
  border-radius: 1.25rem;
  height: 4.5rem;
  margin-top: 1rem;
  width: 100%;
  padding: 1rem 1.5rem;
}

.blueberry-card a {
  color: #FFFFFF;
}

.dashed-btn {
  color: #C4C4C4;
  background-color: #FFF;
  border: 2px dashed #C4C4C4;
  border-radius: 1.25rem;
  height: 5rem;
  margin: 1rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-logo {
  margin: 3rem auto 2rem auto;
  padding: 2.75rem;
  max-width: 202px;
  border-radius: 50%;
  background-color: #FAA7B8;
}

.login-hippa {
  float: left;
  padding: 0 12vw;
}

.login-hippa img {
  max-width: 100px;
}

.login-btn {
  width: 100%;
  height: 3rem;
  background-color: #FFFFFF;
  margin: 0.25rem;
  color: black;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.1);
  border: 0;
}

.account-section h4 {
  padding: 0.75rem 2rem;
  background-color: #FFF;
}

.account-section ul {
  padding: 0.5rem 2rem;
}

.account-section ul li {
  padding: 0.5rem 0;
}

.content-list-element {
  padding: 1rem 1rem;
  border-top: 1px dashed #6096FD;
  height: 7rem;
}

.content-card-element {
  border: 2px solid #6096FD;
  border-radius: 1rem;
  padding: 1.5rem 2.25rem 1.5rem 2.25rem;
  margin-top: 1rem;
  background-color: #FFF;
}

.appointment-card {
  margin-top: 1rem;
  color: #6096FD;
}

.appointment-card-title {
  color: #474747;
  font-weight: 700;
  padding-left: 0.5rem;
}

.appointment-card-body {
  border: 2px solid #6096FD;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.appointment-checkbox-btn {
  width: 100%;
  margin: 0 0.2rem;
  box-shadow: none !important;
  background-color: #FFF;
  padding: 0.5rem;
}

.appointment-checkbox-btn:hover {
  background-color: #FFF;
  color: #0d6efd;
}

.accordion-card {
  background-color: #FFF;
  border: 2px solid #6096FD;
  box-sizing: border-box;
  border-radius: 15px;
  margin-top: 1rem;
  padding: 1rem 1rem;
}

.accordion-card-body {
  height: 100%;
  display: flex;
  justify-content: space-between;
  color: #474747;
  align-items: center;
}

.accordion-card-title {
  height: 100%;
  display: flex;
  justify-content: space-between;
  color: #474747;
  align-items: center;
  margin-bottom: 1rem;
}

.react-daterange-picker {
  width: 100%;
  margin: 0.5rem 0 1rem 0;
}

.cover-template {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.padding-content {
  background: #FFF;
  margin-top: 1rem;
  padding: 1.5rem 1rem;
}

.dental-ai-form {
  font-size: 1rem;
}

.login-icon {
  width: 1.5rem;
  margin-right: 0.25rem;
}

.btn-outline-light:disabled {
  color: black;
  background-color: #FFFFFF;
  cursor: no-drop;
}

@media only screen and (min-width: 768px) {
  .dental-ai-form {
    font-size: 2rem;
  }
}
